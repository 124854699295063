/* Add fonts here */
@font-face {
  font-family: 'Trade Gothic';
  font-weight: 700;
  src: url('./fonts/tradeGothicLTCom.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 700;
  src: url('./fonts/avenirLTComBlack.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-weight: 400;
  font-style: italic;
  src: url('./fonts/avenirLTComLight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Manuka';
  font-weight: 700;
  src: url('./fonts/manukaBold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Untitled Sans';
  font-weight: 500;
  src: url('./fonts/UntitledSans-Regular.woff') format('woff');
  font-display: swap;
}
